import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  UntypedFormGroup,
} from "@angular/forms";
import { AuthService } from "./../auth/auth.service";
import { Const } from "../const/const";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { SiteInfo } from "../entity/get-site-info";
import { LoginUserSite } from "../entity/login-user-site";
import { LoginUserSiteCompany } from "../entity/login-user-site-company";
import { GetAppConstResponse } from "../entity/get-app-const-response";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { AlertMessage } from "src/constants/messages/alert-message";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  getLoginUserUrl: string;
  getAppConstUrl: string;
  idToken: string;
  userTypeSysAdmin = 0;
  userTypeAdmin = 1;
  userTypeUser = 2;
  infoMessage: string;
  isLoaded = false;

  private readonly API_APP_CONSTS_URI =
    "https://common-api.dev.rakusugikenshin.com/getrakusugiappconstants?env=";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private auth: AuthService,
    private httpClient: HttpClient
  ) {
    if (localStorage.getItem("path") != null) {
      this.router.navigate([localStorage.getItem("path")]);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userId: ["", [Validators.required, checkUserId]],
      password: ["", [Validators.required]],
    });

    // お知らせ取得
    this.setInfoMessage();
  }

  onSubmitLogin() {
    if (!navigator.onLine) {
      alert(AlertMessage.NET_ERROR_MESSAGE);
      return;
    }
    this.auth
      .signIn(this.loginForm.value.userId, this.loginForm.value.password)
      .subscribe(
        (response) => {
          this.doGetToken();
          this.auth.loginLogging("1"); // ログイン情報のログを残す
        },
        (error) => {
          if (error.code == "NotAuthorizedException") {
            this.updateUserLockStatus(false);
          } else if (error.code == "UserNotFoundException") {
            alert(AlertMessage.LOGIN_FAILED_MESSAGE);
          } else {
            console.error(error);
            alert(AlertMessage.UNEXPECTED_ERROR_MESSAGE);
          }
        }
      );
  }

  // ユーザーのLockStatus 更新
  updateUserLockStatus(reset: boolean = false) {
    const userID = this.loginForm.value.userId;
    try {
      const url = `${environment.apiUrl}/account_lock/${userID}/${
        reset ? 0 : 1
      }`;
      this.httpClient
        .patch(url, "", {
          headers: new HttpHeaders({
            "x-api-key": environment.apiKey,
            "Content-Type": "application/json",
          }),
        })
        .subscribe(
          (response) => {
            const jsonStr = JSON.stringify(response);
            const jsonObj = JSON.parse(jsonStr);

            const isLocked = jsonObj.result.account_lock.lock_status;

            if (isLocked == 1) {
              alert(AlertMessage.ACCOUNT_LOCKED_MESSAGE);
            } else {
              if (!reset) {
                alert(AlertMessage.LOGIN_FAILED_MESSAGE);
              }
            }
          },
          (error) => {
            if (error.status === 404) {
              alert(AlertMessage.LOGIN_FAILED_MESSAGE);
              return;
            } else {
              alert(AlertMessage.LOGIN_ERROR_MESSAGE);
            }
          }
        );
    } catch (error) {
      alert(AlertMessage.LOGIN_ERROR_MESSAGE);
    }
  }

  doGetToken() {
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        this.doAppConst();
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert(AlertMessage.SESSION_BREAK_MESSAGE);
        this.onClickLogout();
      }
    });
  }

  get userId() {
    return this.loginForm.get("userId");
  }

  doAppConst() {
    this.getAppConstUrl = `${this.API_APP_CONSTS_URI}${environment.apiEnv}`;

    this.httpClient
      .get(this.getAppConstUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetAppConstResponse) => {
          let maintenanceCheck = response.isMaintenance;
          let maintenanceText = response.maintenanceMessage;
          if (maintenanceCheck) {
            alert(maintenanceText);
            this.onClickLogout();
            return;
          } else {
            this.doGetLoginUser();
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert(AlertMessage.ACCOUNT_LOCKED_MESSAGE);
          } else {
            alert(AlertMessage.NOTFOUND_ACCOUNT_INFO_MESSAGE);
          }
        }
      );
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;

    this.httpClient
      .get(this.getLoginUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          Const.loginUser = response.result.login_user;
          Const.site_id = response.result.login_user.sites[0].id;
          Const.mjitUser = Number(response.result.login_user.user_type) === 0;
          if (Const.mjitUser) {
            this.getAllSiteData();
          }
          Const.adminUser = Number(response.result.login_user.user_type) === 1;

          this.updateUserLockStatus(true);

          switch (Number(Const.loginUser.user_type)) {
            case this.userTypeSysAdmin:
              localStorage.setItem("siteId", Const.site_id);
              this.router.navigate(["company-management"]);
              break;
            case this.userTypeAdmin:
              this.router.navigate(["site-select"]);
              break;
            case this.userTypeUser:
              this.router.navigate(["site-select"]);
              break;
            default:
              alert(AlertMessage.NOTFOUND_ACCOUNT_INFO_MESSAGE);
              this.onClickLogout();
              break;
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert(AlertMessage.ACCOUNT_LOCKED_MESSAGE);
          } else {
            alert(AlertMessage.NOTFOUND_ACCOUNT_INFO_MESSAGE);
          }
        }
      );
  }

  // 施設情報の取得（UserType:0はシステム管理者として登録されている施設を全件取得する。）
  getAllSiteData() {
    Const.siteInfo.splice(0);
    const url = `${environment.apiUrl}/sites/sites_info_all/all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          const jsonStr = JSON.stringify(res);
          const jsonObj = JSON.parse(jsonStr);
          for (const site of jsonObj.result.sites as SiteInfo[]) {
            const siteItem = new LoginUserSite();
            siteItem.id = site.id;
            siteItem.name = site.name;
            siteItem.address = site.address;
            siteItem.updated_at = site.updated_at;
            siteItem.created_at = site.created_at;
            siteItem.company = new LoginUserSiteCompany();
            siteItem.company.id = site.company_id;
            siteItem.company.name = site.company_name;
            Const.siteInfo.push(siteItem);
          }
        },
        () => {
          alert(AlertMessage.NOTFOUND_SITE_INFO_MESSAGE);
        }
      );
  }

  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  /**
   * S3からログイン画面用のお知らせを取得し、設定する
   */
  setInfoMessage() {
    try {
      const url = `${environment.apiUrl}/before_login/json`;

      this.httpClient
        .get(url, {
          headers: new HttpHeaders({
            "x-api-key": environment.apiKey,
            "Content-Type": "application/json",
          }),
        })
        .pipe(
          catchError((error) => {
            console.log("ログイン画面メッセージが取得できませんでした。");
            console.log(error);
            this.isLoaded = true;
            return of(error);
          })
        )
        .subscribe((response) => {
          const jsonStr = JSON.stringify(response);
          const jsonObj = JSON.parse(jsonStr);
          this.infoMessage = jsonObj["result"]["json"]["infoMessage"];
          this.isLoaded = true;
        });
    } catch (error) {
      console.log("ログイン画面メッセージが取得できませんでした。");
      console.log(error);
      this.infoMessage = "";
      this.isLoaded = true;
    }
  }
}

// 半角英数字,+,_,-,@でなければfalseを返すバリデーションチェック
function checkUserId(formControl: UntypedFormControl) {
  const val = formControl.value;
  const REGPATTERN = /^[a-zA-Z0-9.\+_\-\@]+?$/;
  const count = val.split("@").length - 1;
  if (REGPATTERN.test(val) && count <= 1) {
    return null;
  } else {
    return { checkUserId: { valid: false } };
  }
}
